import React, { Fragment, MouseEvent } from 'react';
import { Link, useHistory } from "react-router-dom";
import { menusStateTypes, footerPropsTypes } from "../Types/index";

const Footer = ({ footerManus, socialManus, classTmpName }: footerPropsTypes) => {

    const history = useHistory();

    const onPageChange = (e: MouseEvent, slug: string, type: string, url: string): void => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        (type === 'custom') ? window.open(url, '_blank') : history.push((slug === 'home') ? '/' : `/${slug}`);
    }

    return (
        <footer className={classTmpName}>
            <div className="container">
                <div className="footer-link">
                    <div className="footer-block">
                        {
                            (footerManus.length > 0) && footerManus.map((menu: menusStateTypes) => {
                                return (
                                    <Fragment key={menu.post_id}>
                                        <Link to={
                                            { pathname: (menu.type === 'custom') ? menu.url : `/${(menu.post_name === 'home') ? '' : menu.post_name}` }
                                        } onClick={(e) => onPageChange(e, menu.post_name, menu.type, menu.url)} target={(menu.type === 'custom' ? '_blank' : undefined)} rel={(menu.type === 'custom' ) ? 'noreferrer' : undefined}>
                                            {menu.title}
                                        </Link>
                                    </Fragment>
                                )
                            })
                        }
                    </div>

                    <div className="footer-block">
                        <div className="social-icon">
                            {
                                (socialManus.length > 0) && socialManus.map((socialFooterItem: menusStateTypes, i: number) => {
                                    return (
                                        <a href={`${socialFooterItem.url}`} target="_blank" rel="noreferrer" key={i}>
                                            <img className="lazyload" data-src={`/images/${socialFooterItem.post_name}-grey.svg`} alt={`${socialFooterItem.post_name}-img`} width="18" height="18" />
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <p>© Copyright 2021, JuicyApps</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
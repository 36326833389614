export const singleProductDefaultStateData = {
    post: {
        "ID": 69,
        "juicyapps_attachment_ids": [
            "/images/age-yard01.svg",
            "/images/age-yard02.svg",
            "/images/age-yard03.svg"
        ],
        "juicyapps_productFeature": [
            "<p>Add a pop-up window to your website and verify the age of the visitor before allowing them to view your content.</p><p><b>Customize a variety of features in the Age Yard Box</b></p><ul><li>Minimum age</li><li>Background image</li><li>Logo image</li><li>Title text</li><li>Message text</li><li>Yes/No button text</li><li>Set the amount of days to save cookies</li><li>Turn on debug to test popup changes without saving cookies</li><li>Customize the Success & Failure messages (title & text)</li></ul>",
            "<ul><li>No code required to incorporate the app into your store</li><li>Restrict under-age users with a personalized message</li><li>Have complete control over the validation settings. Decide on the minimum age and many more</li><li>No need to confgure separately for mobile devices. It is fully responsive so it works & looks great on any sized desktop, tablet, and mobile device</li><li>New preset templates to save your time</li><li>Page-specifc display criteria to protect certain pages from access by minors</li></ul>",
            "<ul><li>Fully customizable popup to match up with your store</li><li>Update the heading & sub-headings as needed</li><li>Update the text on the buttons as needed</li><li>Set your desired background image for the popup</li><li>Popup preview - To easily view the changes you made</li><li>Get counts of verifed, unverifed & total verifed users</li><li>Personalize your popup with a logo of your store</li><li>Responsive nature of the popup for better user experience</li><li>Easy to install and manage</li><li>Goes with every theme</li><li>24*7*365 support</li></ul>"
        ],
        "juicyapps_productFeaturePositions": ["left", "left", "left"],
        "post_excerpt": "",
        "post_title": "What is Lorem Ipsum"
    }
};

export const landingPageDefaultStateData = {
    "features": [
        {
            ID: 79,
            juicyapps_features_image: "/images/easy-installation.svg",
            juicyapps_features_subtitle: "Look for the app, Click on Install, Link your store and BOOM! App is installed.",
            juicyapps_features_title: "Easy Installation"
        },
        {
            ID: 80,
            juicyapps_features_image: "/images/compatibility-first.svg",
            juicyapps_features_subtitle: "We understand the nature of strings between software and compatibility. Pretty tangled! Well not any more...",
            juicyapps_features_title: "Compatibility First!"
        },
        {
            ID: 81,
            juicyapps_features_image: "/images/tailor-made.svg",
            juicyapps_features_subtitle: "Look for the app, Click on Install, Link your store and BOOM! App is installed.",
            juicyapps_features_title: "Tailor Made"
        }
    ],
    "page": {
        ID: 57,
        juicyapps_pages_image: "/images/why-we.png",
        post_content: '<div class="container"><div class="about-outer"><div class="about-text"><h1 class="section-heading">About JuicyApps</h1>JuicyApps is a venture of Tech-Prastish (www.tech-prastish.com) which has an extensive experience of 12 years with ecommerce development. Tech Prastish has worked on more than 25 ecommerce CMS &amp; SAAS.<h1 class="section-heading">Why We !!</h1>We build apps, plugins, extensions or modules for Shopify, BigCommerce, CS-Cart, NOPCommerce, Xcart &amp; Wordpress. Be it SAAS or Open-Source we’ve a vision to serve all nooks &amp; corners of app development.To avoid any hiccups and ensure a seamless uptime for the apps, we’ve been using AWS dedicated hosting services.We have NDAs &amp; strong policies to have a secure enviroment where user / platform data never get on a toss.We believe in providing best of the features within a reasonable subscription costing.We believe in continued investment on building &amp; updating our apps to match our setps with today’s world.A “closed loop” feedback / complaints system to ensure a lightning fast solution.</div><div class="about-img"><img src="/images/why-we.svg" alt="about-img" width="527" height="512"></div></div></div>',
        yoast_head: {
            head: "<title>Home - juicyapps-admin</title>"
        }
    },
    "products": [
        {
            ID: 69,
            juicyapps_productFeatureTitles: [
                "Age Yard Text1",
                "Age Yard Text2",
                "Age Yard Text3"
            ],
            juicyapps_products_image: "/images/age-yard-box.svg",
            post_excerpt: "",
            post_title: "Age Yard Box"
        },
        {
            ID: 79,
            juicyapps_productFeatureTitles: [
                "Age Yard Text1",
                "Age Yard Text2",
                "Age Yard Text3"
            ],
            juicyapps_products_image: "/images/age-yard-box.svg",
            post_excerpt: "",
            post_title: "Age Yard Box One"
        }
    ],
    "services": [
        {
            ID: 61,
            juicyapps_services_image: "/images/store-setup.svg",
            juicyapps_services_subtitle: "At JuicyApps, we promise quick and effective Setup and Configuration of your Shopify store. We bring you a responsive Shopify store that delivers seamless customer experience across all screen platforms. When it comes to fast and reliable Shopify setup, no-one does it better than JuicyApps",
            juicyapps_services_title: "Shopify Store Setup",
            post_title: "Shopify Store Setup"
        },
        {
            ID: 85,
            juicyapps_services_image: "/images/theme-design.svg",
            juicyapps_services_subtitle: "Your search for a fully customized Shopify theme design ends at JuicyApps. Why? Because we leverage upon our profound Shopify theme design experience to give your ecommerce website the right look and feel. We know how to mark your presence in the digital world.",
            post_title: "Shopify Theme Design"
        },
        {
            ID: 86,
            juicyapps_services_image: "/images/psd-shopify.svg",
            juicyapps_services_subtitle: "Got your own design for your Shopify website? Awesome! Simply send us the PSD file of the design. Our experienced and exceptionally able Shopify team will implement it in no time! Put an instant smile on your audience’s face with our fast and reliable PSD to Shopify services.",
            juicyapps_services_title: "PSD to Shopify",
            post_title: "PSD to Shopify"
        }
    ],
    "slides": [
        {
            ID: 62,
            juicyapps_slides_image: "/images/banner.svg",
            juicyapps_slides_subtitle: "of your store!",
            juicyapps_slides_title: "We extend the functionality"
        }
    ]
};

export const menusDefaultStateData = {
    Header: [
        {
            parent_id: "0",
            post_id: 179,
            post_name: "home",
            title: "Home",
            type: "page",
            url: "https://demos.techpss.com/juicyapps-admin/home/"
        },
        {
            parent_id: "0",
            post_id: 177,
            post_name: "about",
            title: "About",
            type: "page",
            url: "https://demos.techpss.com/juicyapps-admin/about/"
        },
        {
            parent_id: "0",
            post_id: 178,
            post_name: "privacy-policy",
            title: "Privacy Policy",
            type: "page",
            url: "https://demos.techpss.com/juicyapps-admin/privacy-policy/"
        }
    ],
    Footer: [
        {
            parent_id: "0",
            post_id: 119,
            post_name: "privacy-policy",
            title: "Privacy Policy",
            type: "page",
            url: "https://juicyapps-admin.techpss.com/privacy-policy/"
        },
        {
            parent_id: "0",
            post_id: 135,
            post_name: "about",
            title: "About",
            type: "page",
            url: "https://juicyapps-admin.techpss.com/about/"
        },
        {
            parent_id: "0",
            post_id: 342,
            post_name: "blog",
            title: "Blog",
            type: "page",
            url: "https://juicyapps-admin.techpss.com/blog/"
        }
    ],
    Social: [
        {
            parent_id: "0",
            post_id: 213,
            post_name: "facebook",
            title: "Facebook",
            type: "page",
            url: "https://www.facebook.com/"
        }
    ],
};

export const generalCompDefaultData = {
    post_title: 'What is Lorem Ipsum',
    post_excerpt: '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>',
    post_content: '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English</p>',
    featured_image: '/images/tp-logo.svg'
}

export const routesDefaultData = [
    {
        post_id: 179,
        post_name: "home",
        post_type: "page",
        title: "Home",
        url: "https://juicyapps-admin.techpss.com/home/"
    },
    {
        post_id: 351,
        post_name: "products/age-yard-box",
        post_type: "products",
        title: "Products",
        url: "https://juicyapps-admin.techpss.com/products/age-yard-box/"
    },
    {
        post_id: 300,
        post_name: "company",
        post_type: "page",
        title: "Company",
        url: "https://juicyapps-admin.techpss.com/company/"
    },
    {
        post_id: 301,
        post_name: "about",
        post_type: "page",
        title: "About",
        url: "https://juicyapps-admin.techpss.com/about/"
    },
    {
        post_id: 304,
        post_name: "contact-us",
        post_type: "page",
        title: "Contact us",
        url: "https://juicyapps-admin.techpss.com/contact-us/"
    },
    {
        post_id: 326,
        post_name: "testimonials",
        post_type: "page",
        title: "Testimonials",
        url: "https://juicyapps-admin.techpss.com/testimonials/"
    },
    {
        post_id: 327,
        post_name: "team",
        post_type: "page",
        title: "Team",
        url: "https://juicyapps-admin.techpss.com/team/"
    },
    {
        post_id: 344,
        post_name: "tester",
        post_type: "custom",
        title: "Tester",
        url: "http://xyz.com"
    },
    {
        post_id: 119,
        post_name: "privacy-policy",
        post_type: "page",
        title: "Privacy Policy",
        url: "https://juicyapps-admin.techpss.com/privacy-policy/"
    },
    {
        post_id: 135,
        post_name: "about",
        post_type: "page",
        title: "About",
        url: "https://juicyapps-admin.techpss.com/about/"
    },
    {
        post_id: 342,
        post_name: "blog",
        post_type: "page",
        title: "Blog",
        url: "https://juicyapps-admin.techpss.com/blog/"
    },
];



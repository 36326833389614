import React, { useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { menusStateTypes, headerPropsTypes } from "../Types/index";

const Header = ({ openNavDrawer, closeNavDrawer, isDrawerOpen, headerManus }: headerPropsTypes) => {

    // handle sub menu open close drawer
    const [subMenuDrawer, SetsubMenuDrawer] = useState<string | null>(null);
    const [prevActiveSubMenu, setPrevActiveSubMenu] = useState<string | null>(null);

    // Handle Sub menu when mobile
    const handleSubMenuDrawer = (id: string) => {
        if (id === prevActiveSubMenu) {
            SetsubMenuDrawer(null);
            setPrevActiveSubMenu(null);
        } else {
            SetsubMenuDrawer(id);
            setPrevActiveSubMenu(id);
        }
    }

    return (
        <header>
            <div className="container">
                <div className="nav-bar-wrap">
                    <div className="logo">
                        <Link to="/">
                            <img className="lazyload" data-src="/images/logo.svg" alt="logo" width={190} height={50} />
                        </Link>
                    </div>
                    <span className="mobile-toggle"><img className="lazyload" data-src="/images/menu.svg" alt="mobile-toggle" width={24} height={24} onClick={openNavDrawer} /></span>
                    <ul className={(isDrawerOpen === null) ? 'main-menu' : (isDrawerOpen === true) ? 'main-menu open-nav' : 'main-menu close-nav'} id="mobile-menu">
                        <a href="/#" className="closebtn" onClick={closeNavDrawer}>
                            <img className="lazyload" data-src="/images/close.svg" alt="close" width={20} height={20} />
                        </a>

                        {
                            (headerManus.length > 0) && headerManus.map((menu: menusStateTypes, i: number) => {
                                return (
                                    <li className="dropdown-menu" key={i}>

                                        <Link to={
                                            { pathname: (menu.type === 'custom') ? menu.url : `/${(menu.post_name === 'home') ? '' : menu.post_name}` }
                                        } target={(menu.type === 'custom' ? '_blank' : undefined)} rel={(menu.type === 'custom' ) ? 'noreferrer' : undefined}>
                                            {menu.title}
                                        </Link>

                                        {
                                            ('submenu' in menu) && <Fragment>

                                                <span className={(subMenuDrawer === `ul-${i}` ? 'dropbtn open' : 'dropbtn')} onClick={() => handleSubMenuDrawer(`ul-${i}`)}>
                                                    <img className="lazyload" data-src="/images/arrow-down.svg" alt="arrow-down" width={10} height={10} />
                                                </span>
                                                <ul className={(subMenuDrawer === `ul-${i}` ? 'custom-dropdown show' : 'custom-dropdown')} data-id={`ul-${i}`}>
                                                    {
                                                        (menu.submenu) && menu.submenu.map((submen: any, i: number) => {
                                                            return (
                                                                <li key={i}>

                                                                    <Link to={
                                                                        { pathname: (submen.type === 'custom') ? submen.url : `/${(submen.post_name === 'home') ? '' : submen.post_name}` }
                                                                    } target={(submen.type === 'custom' ? '_blank' : undefined)} rel={(menu.type === 'custom' ) ? 'noreferrer' : undefined}>
                                                                        {submen.title}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </Fragment>
                                        }
                                    </li>
                                )
                            })
                        }

                        <li>
                            <div className="button-wrapper">
                                <Link to={'/#ticket'} className="default-btn">Request a Demo</Link>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;
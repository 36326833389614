import React, { Fragment, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import Loader from "../Components/Loader";
import Contact from "../Components/Contact";
import Card from "../Components/Card";
import { landingPageDefaultStateData } from "../Json/DefaultApiData";
import { postApiHit } from '../Utils/RestClient';
import { tryRequire } from "../Utils/Helpers";

const Landing = () => {

    const [data, setData] = useState(landingPageDefaultStateData);
    const [isJson, setIsJson] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Corousel Setiings
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        touchMove: true,
        swipe: true,
        responsive: [{ breakpoint: 767, settings: { dots: true, arrows: false } }]
    };

    useEffect(() => {

        const fetchLandingPageData = async () => {

            const slug = 'landing';
            const tmp = await tryRequire(slug);
    
            if (tmp !== false) {
                
                const HomeData = await require(`../Json/${slug}.json`);
                setData(HomeData);
                setIsJson(true);
    
            } else {

                setIsJson(false);
                postApiHit('wp-json/wp/all/posts', 'post', { terms: 'home' }).then(response => {
                    setIsLoading(false);
                    setData(response);
                });
            }
        };

        fetchLandingPageData();

    }, []);

    if (isJson === false && isLoading === true) {

        return <Loader />;

    } else {

        return (
            <Fragment>

                {
                    (data.page) && <Helmet>{parse(data.page.yoast_head.head)}</Helmet>
                }

                <div className="top-shape">
                    <img src="/images/bg-shape-1.svg" alt="top-shape" width={960} height={904} />
                </div>

                {/* Banner Section Start */}
                {
                    (data.slides.length > 0) && <section className="banner-section">
                        <div className="container">
                            {
                                data.slides.map((slide: any) => {
                                    return (
                                        <div className="banner-outer" key={slide.ID}>
                                            <div className="banner-text">
                                                <h1 className="section-heading">{slide.juicyapps_slides_title}
                                                    <span>{slide.juicyapps_slides_subtitle}</span>
                                                </h1>
                                            </div>
                                            <div className="banner-img">
                                                <img src={slide.juicyapps_slides_image} alt="banner" width={667} height={504} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>
                }
                {/* Banner Section End */}
                {/*Step Section Start */}
                {
                    (data.features.length > 0) && <section className="featured-section">
                        <div className="container">
                            <div className="three-column-grid card-style">
                                {
                                    data.features.map((feature: any) => {
                                        return (
                                            <Card key={feature.ID} imageSrc={feature.juicyapps_features_image} h4Content={feature.juicyapps_features_title}
                                                pContent={feature.juicyapps_features_subtitle} imageHeight={60} imageWidth={60} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                }
                {/* Step Section End */}
                {/* Age Yard Section Start */}

                {
                    (data.products.length > 0) && <section className="age-yard-section">
                        <div className="container">
                            <Slider {...settings}>
                                {
                                    data.products.map((product: any, i: number) => {
                                        return (
                                            <div key={i}>
                                                <div className="age-yard-outer">
                                                    <div className="age-yard-img">
                                                        <img className="lazyload" data-src={`${product.juicyapps_products_image}`} alt="age-yard-img" width={644} height={531} />
                                                    </div>
                                                    <div className="age-yard-text">
                                                        <h1 className="section-heading">{product.post_title}</h1>
                                                        <ul className="age-list">
                                                            {
                                                                (product.juicyapps_productFeatureTitles.length > 0) && product.juicyapps_productFeatureTitles.map((tmpProdFeatureTitle: any, i: number) => {
                                                                    return (
                                                                        <li key={i}>
                                                                            <img className="lazyload" data-src="/images/right-arrow.svg" alt="arrow" width={12} height={12} /> {tmpProdFeatureTitle}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>

                                                        <div className="button-wrapper ancher-btn">
                                                            <Link to={`/products/${product.post_name}`} className="default-btn">See Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </ Slider>

                        </div>
                        <div className="age-yard-shape">
                            <img className="lazyload" data-src="/images/bg-shape-2.svg" alt="age-yard-shape" height={890} width={945} />
                        </div>

                    </section>
                }

                {/* Age Yard Section End */}
                {/* About Section Start */}
                {
                    (data.page) && <section className="about-section">
                        <div className="container">
                            <div className="about-outer">
                                {parse(data.page.post_content)}
                            </div>
                        </div>
                    </section>
                }
                {/* About Section End */}
                {/* Service Section Start */}
                {
                    (data.services.length > 0) && <section className="service-section section-padding">
                        <div className="container">
                            <h1 className="section-heading center-heading">Shopify Partner Services</h1>
                            <div className="three-column-grid card-style card-shadow">
                                {
                                    data.services.map((service: any) => {
                                        return (
                                            <Card key={service.ID} imageSrc={service.juicyapps_services_image} h4Content={service.post_title}
                                                pContent={service.juicyapps_services_subtitle} imageHeight={60} imageWidth={60} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                }
                {/* Service Section End */}
                {/* Ticket Form Section Start */}
                <Contact products={data.products} />
                {/* Ticket Form Section End */}
            </Fragment>
        );
    }
};

export default Landing;

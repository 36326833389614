import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { postApiHit } from "../Utils/RestClient";
import { routesDefaultData } from "../Json/DefaultApiData";
import { tryRequire } from "../Utils/Helpers";

import Loader from "../Components/Loader";
import Landing from "../Pages/Landing";

const GeneralComponent = lazy(() => import("../Components/GeneralComponent")); // Load child component lazy
const Faq = lazy(() => import("../Pages/Faq")); // Load child component lazy
const SingleProduct = lazy(() => import("../Pages/SingleProduct")); // Load child component lazy
const CustomPosts = lazy(() => import("../Pages/CustomPosts")); // Load child component lazy
const Posts = lazy(() => import("../Pages/Posts")); // Load child component lazy
const InnerPosts = lazy(() => import("../Pages/InnerPosts")); // Load child component lazy
const Search = lazy(() => import("../Pages/Search")); // Load child component lazy
const SinglePost = lazy(() => import("../Pages/SinglePost")); // Load child component lazy
const Page404 = lazy(() => import("../Pages/Page404")); // Load child component lazy
const ContactUs = lazy(() => import("../Pages/ContactUs")); // Load child component lazy

const Routes = () => {

    const [routes, setRoutes] = useState(routesDefaultData);

    useEffect(() => {

        const fetchRoutes = async () => {

            const slug = 'allroutes';
            const tmp = await tryRequire(slug);
    
            if (tmp !== false) {
                
                const tmpData = await require(`../Json/${slug}.json`);
                setRoutes(tmpData.routes);
    
            } else {

                postApiHit('wp-json/wp/allroutes/posts/', 'get', {}).then(res => {
                    setRoutes(res.routes);
                });
            }
        };

        fetchRoutes();

    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path={'/'} component={Landing} exact />
                <Route path={'/age-yard-box'} component={Landing} exact />
                <Route path={'/age-yard-box/faq'} component={Faq} exact />
                <Route path={'/products/:id'} component={SingleProduct} exact />
                <Route path={['/team', '/testimonials']} component={CustomPosts} exact />
                <Route path={'/contact-us'} component={ContactUs} exact />
                <Route path={'/blog'} component={Posts} exact />
                <Route path={'/blog/:type/:slug'} component={InnerPosts} exact />
                <Route path={'/blog/:id'} component={SinglePost} exact />
                <Route path={'/search'} component={Search} exact />
                {
                    (routes.length > 0) && routes.map((route: any) => {
                        return (route.post_type === 'page') && <Route key={route.post_id} path={`/${route.post_name}`} component={GeneralComponent} exact />
                    })
                }
                <Route component={Page404} exact />
            </Switch>
        </Suspense>
    );
};

export default Routes;
import React, { useState } from 'react';
import { postApiHit } from "../Utils/RestClient";
import { ContactPropsTypes } from "../Types";

const Contact = ({ products }: ContactPropsTypes) => {

    // Handle Form submission
    const [fields, setFields] = useState({ email: '', subject: '', appName: 'Age Yard Box', description: '' });
    const [errors, setErrors] = useState({ email: '', subject: '', description: '' });
    const [formSubmitStatus, setFormSubmitStatus] = useState('');

    const [isShowToast, setIsShowToast] = useState<string>('');
    const [submitMessage, setSubmitMessage] = useState('');
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

    // Handle Dropdown Menus
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [selectedSubmenu, setSelectedSubmenu] = useState<string>('Select Product');

    // Submit a ticket & shoot the mail
    const submitTicket = async (e: any) => {

        e.preventDefault();
        setFormSubmitStatus('');

        if (products.length > 1 && selectedSubmenu === 'Select Product') {

            alert('Please select one product');

        } else {

            setIsFormLoading(true);
            postApiHit('wp-json/wp/v1/posts/', 'post', fields).then(response => {

                setSubmitMessage('We appreciate that you’ve taken the time to write us. We’ll get back to you very soon.');
                setFormSubmitStatus('success'); // Show success msg
                setIsShowToast('show');
                setFields({ ...fields, email: '', subject: '', description: '' });
                setIsFormLoading(false);

                setTimeout(() => {
                    setIsShowToast('');
                }, 3000);

            }).catch((error) => {

                console.log('error.response', error.response);
                setSubmitMessage('Error occured! please try again after some tiem.');
                setFormSubmitStatus('error'); // Show success msg 
                setIsShowToast('show'); 
                setIsFormLoading(false);

                setTimeout(() => {
                    setIsShowToast('');
                }, 3000);

            });
        }
    }

    // Handle the input box changes
    const handleChange = (e: any) => {
        let message;
        // eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const { name, value } = e.target;
        switch (name) {
            case 'email':
                message = (value) ? validEmailRegex.test(value) ? '' : 'Email is not valid!' : '';
                setErrors({ ...errors, [name]: message });
                break;
            case 'subject':
                message = (value) ? '' : 'Subject should not be empty!';
                setErrors({ ...errors, [name]: message });
                break;
            case 'description':
                message = (value) ? '' : 'Description should not be empty!';
                setErrors({ ...errors, [name]: message });
                break;
            default:
                break;
        }

        setFields({ ...fields, [name]: value });
    }

    // console.log('products', products);

    return (
        <section className="ticked-form-section section-padding bglight" id="ticket">
            <div className="container">
                <h1 className="section-heading">Submit a Ticket</h1>
                <form className="form-outer" onSubmit={submitTicket} id="contact-form">
                    <div className="form-block">
                        <input type="email" placeholder="Email Address" required={true} name="email" value={fields.email} onChange={handleChange} aria-label={'email'} />
                    </div>
                    <div className="form-block">

                        {
                            (products.length > 1) ? <div className="custom-select-wrapper"> <div className={(isDropdownOpen === true) ? 'custom-select open' : 'custom-select'} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                <div className="custom-select-trigger"><span>{selectedSubmenu}</span>
                                    <div className="arrow"></div>
                                </div>
                                <input type="hidden" value={selectedSubmenu} name="appName" />
                                <div className="custom-options">
                                    {
                                        products.map((product: any, i: number) => {
                                            return (
                                                <span key={i} className={(selectedSubmenu === product.post_title) ? 'custom-option selected' : 'custom-option'} onClick={() => setSelectedSubmenu(product.post_title)}>{product.post_title}</span>
                                            )
                                        })
                                    }
                                </div>
                            </div> </div> : <input type="text" value="Age Yard Box" disabled={true} name="appName" aria-label={'appName'} />
                        }

                    </div>
                    <div className="form-block">
                        <input type="text" placeholder="Subject" name="subject" value={fields.subject} onChange={handleChange} aria-label={'subject'} required={true} />
                    </div>
                    <div className="form-block textarea-block">
                        <textarea placeholder="Description" name="description" value={fields.description} onChange={handleChange} aria-label={'description'} required={true} />
                    </div>

                    {
                        (formSubmitStatus === 'success') && <div className={`message success-message ${isShowToast}`}>
                            <img className="message-close" onClick={() => setIsShowToast('')} src="images/close.svg" alt="close img" width="12" height="12" />
                            <h6>Thanks</h6>
                            <p>{submitMessage}</p>
                        </div>
                    }

                    {
                        (formSubmitStatus === 'error') && <div className={`message error-message ${isShowToast}`}>
                            <img className="message-close" onClick={() => setIsShowToast('')} src="images/close.svg" alt="close img" width="12" height="12" />
                            <h5>Error occured</h5>
                            <p>{submitMessage}</p>
                        </div>
                    }

                    <div className={((isFormLoading === true || formSubmitStatus === 'success' || formSubmitStatus === 'error')) ? 'button-wrapper button-loader' : 'button-wrapper'}>
                        {/* <input className="default-btn" type="submit" name="Hover" value="Submit Ticket" /> */}
                        <button type="submit" form="contact-form" value="Submit" className="default-btn" >Send Message</button>

                        {
                            (isFormLoading === true) && <div className="btn-loader"></div>
                        }

                        {
                            (formSubmitStatus === 'success' || formSubmitStatus === 'error') && <div className="btn-tick"></div>
                        }

                    </div>
                </form>
            </div>
        </section>
    );
};

export default Contact;
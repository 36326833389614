import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from "../Router/Routes"; // Load Routes
import Loader from "../Components/Loader";
import { HelmetProvider } from 'react-helmet-async';
import { postApiHit } from '../Utils/RestClient';
import { tryRequire } from "../Utils/Helpers";
import { menusDefaultStateData } from "../Json/DefaultApiData";

import Header from "../Components/Header";
import Footer from "../Components/Footer";

const Front = () => {

    const [isJson, setIsJson] = useState<boolean>(false);
    const [data, setData] = useState(menusDefaultStateData);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFooterShow, setIsFooterShow] = useState<boolean>(false);
    const [isDrawerOpen, SetIsDrawerOpen] = useState<boolean | null>(null);

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent);
    });

    useEffect(() => {

        const fetchMenus = async () => {

            const slug = 'allmenus';
            const tmp = await tryRequire(slug);
    
            if (tmp !== false) {
                
                const tmpData = await require(`../Json/${slug}.json`);
                setData(tmpData);
                setIsJson(true);
    
            } else {

                setIsJson(false);
                postApiHit('wp-json/wp/allmenus/posts/', 'get', {}).then(response => {
                    setIsLoading(false);
                    setData(response);
                });
            }
        };

        fetchMenus();

    }, []);

    const scrollEvent = () => {
    
        if (window.pageYOffset > 50) {
            setIsFooterShow(true);
        }
    }

    // Open Drawer When Mobile
    const openNavDrawer = () => {
        SetIsDrawerOpen(true);
        document.body.classList.add('bodyactive');
    }

    // Close Drawer When Mobile
    const closeNavDrawer = (e: any) => {
        e.preventDefault();
        SetIsDrawerOpen(false);
        document.body.classList.remove('bodyactive');
    }

    if (isJson === false && isLoading === true) {

        return <Loader />;

    } else { 

        return (
            <HelmetProvider>
                <div className="main-wrapper">
                    <Suspense fallback={<Loader />}>
                        <Router>
                            <Header
                                openNavDrawer={openNavDrawer}
                                closeNavDrawer={closeNavDrawer}
                                isDrawerOpen={isDrawerOpen}
                                headerManus={data.Header} />
    
                            <div className="main-content">
                                <Routes />
                            </div>
                            <Footer footerManus={data.Footer} socialManus={data.Social} classTmpName={(isFooterShow === true) ? 'footer' : 'display-none'} />
                        </Router>
                    </Suspense>
                </div>
                <div className={(isDrawerOpen === true) ? 'overlay active' : 'overlay'} onClick={closeNavDrawer}></div>
            </HelmetProvider>
        );

    }
};

export default Front;
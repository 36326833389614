import React from 'react';
import { CardPropsTypes } from "../Types";

const Card = ({ 
    imageClassType = 'default-card-img', 
    imageSrc = '/images/user.svg', 
    h4Content, 
    h6Content, 
    pContent, 
    socialIcons = {},
    imageWidth,
    imageHeight,
    cardPosition = ''
}: CardPropsTypes) => {

    return (
        <div className="column">
            <div className={`card ${cardPosition}`}>
                <div className="card-icon">
                    <img className={`lazyload ${imageClassType}`} data-src={imageSrc} alt="team-img" width={imageWidth} height={imageHeight} />
                </div>
                <div className="card-content">
                    {
                        (h4Content && h4Content !== '') && <h4 className="card-content-title">{h4Content}</h4>
                    }
                    {
                        (h6Content && h6Content !== '') && <h6 className="card-subtitle">{h6Content}</h6>
                    }
                    {
                        (pContent && pContent !== '') && <p>{pContent}</p>
                    }
                    {
                        (Object.keys(socialIcons).length > 0) && <div className="team-social-icon">
                            {
                                Object.keys(socialIcons).map((key: string) => {

                                    return (
                                        <a href={socialIcons[key]} target="_blank" rel="noreferrer" key={key}>
                                            <img className="lazyload" data-src={`/images/${key}-grey.svg`} alt={`${key}`} width={20} height={20} />
                                        </a>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Card;
import * as types from '../type';

const initialState = {
    payload: {},
    post: {},
    indexLoading: true,
    singleLoading: true,
    indexInnerPageLoading: true,
    error: null
}

const Post = ( state = initialState, action: any ) => {

    switch (action.type) {

        case types.FETCH_POSTS:
            return{
                ...state,
                payload: action.payload,
                indexLoading: false,
                indexInnerPageLoading: true,
                error: null
            }

        case types.FETCH_POSTS_ERROR:
            return{
                ...state,
                error: action.payload,
                indexLoading: false,
                indexInnerPageLoading: true
            }    
            
        case types.FETCH_SINGLE_POST:
            return{
                ...state,
                post: action.payload,
                singleLoading: false,
                error: null
            }

        case types.FETCH_SINGLE_POST_ERROR:
            return {
                ...state,
                error: action.payload,
                singleLoading: false
            }

        case types.FETCH_POSTS_INNER_PAGE:
            return{
                ...state,
                payload: action.payload,
                indexInnerPageLoading: false,
                error: null
            }            

        case types.FETCH_POSTS_INNER_PAGE_ERROR:
            return {
                ...state,
                error: action.payload,
                indexInnerPageLoading: false
            }

        default:
            return state
    }
}

export default Post;
import * as types from '../type';
import { generalCompDefaultData } from "../../Json/DefaultApiData";

const initialState = {
    payload: generalCompDefaultData,
    loading: true,
    error: null
}

const GeneralPage = ( state = initialState, action: any ) => {

    switch (action.type) {

        case types.FETCH_PAGE:
            return{
                ...state,
                payload: action.payload,
                loading: false,
                error: null
            }                         

        case types.FETCH_PAGE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        default:
            return state
    }
}

export default GeneralPage;
import * as types from '../type';
import { menusDefaultStateData } from "../../Json/DefaultApiData";

const initialState = {
    payload: menusDefaultStateData,
    loading: true,
    error: null
}

const Menus = ( state = initialState, action: any ) => {

    switch (action.type) {

        case types.GET_MENUS:
            return{
                ...state,
                payload: action.payload,
                loading: false,
                error: null
            }

        default:
            return state
    }
}

export default Menus;
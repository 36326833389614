import React from 'react';

const Loader = () => {
    return (
        <div className="juicy-loader-wrap" id="juicy-loader">
            <div className="juicy-loader-outer">
                <div className="loader-outer"></div>
                <div className="loader-inner"></div>
                <div className="loader-icon">
                    <img src={"/images/logo.svg"} alt="logo" width="190" height="50" />
                </div>
            </div>
        </div>
    );
};

export default Loader;
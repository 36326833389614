import * as types from '../type';
import { landingPageDefaultStateData } from "../../Json/DefaultApiData";

const initialState = {
    payload: landingPageDefaultStateData,
    loading: true,
    error: null
}

const Landing = ( state = initialState, action: any ) => {

    switch (action.type) {

        case types.FETCH_LANDING_PAGE:
            return{
                ...state,
                payload: action.payload,
                loading: false,
                error: null
            }

        case types.FETCH_LANDING_PAGE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        default:
            return state
    }
}

export default Landing;
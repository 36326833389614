import { combineReducers } from 'redux';
import Menus from './Menus';
import GeneralPage from "./generalPage";
import Landing from "./Landing";
import Product from "./Product";
import CustomPost from "./CustomPost";
import Posts from "./Posts";

export default combineReducers({
   
    generalPageReducer: GeneralPage,
    menusReducer: Menus,
    landingReducer: Landing,
    productReducer: Product,
    customPostReducer: CustomPost,
    postsReducer: Posts,
});
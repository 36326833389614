import * as types from '../type';
import { singleProductDefaultStateData } from "../../Json/DefaultApiData";

const initialState = {
    payload: singleProductDefaultStateData,
    loading: true,
    error: null
}

const Product = ( state = initialState, action: any ) => {

    switch (action.type) {

        case types.FETCH_PRODUCT:
            return{
                ...state,
                payload: action.payload,
                loading: false,
                error: null
            }  
        case types.FETCH_PRODUCT_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }                           

        default:
            return state
    }
}

export default Product;
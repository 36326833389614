export const GET_MENUS = 'GET_MENUS';

export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_ERROR = 'FETCH_PAGE_ERROR';

export const FETCH_LANDING_PAGE = 'FETCH_LANDING_PAGE';
export const FETCH_LANDING_PAGE_ERROR = 'FETCH_LANDING_PAGE_ERROR';

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR';

export const FETCH_CUSTOM_POSTS = 'FETCH_CUSTOM_POSTS';
export const FETCH_CUSTOM_POSTS_ERROR = 'FETCH_CUSTOM_POSTS_ERROR';

export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POSTS_ERROR = 'FETCH_POSTS_ERROR';

export const FETCH_SINGLE_POST = 'FETCH_SINGLE_POST';
export const FETCH_SINGLE_POST_ERROR = 'FETCH_SINGLE_POST_ERROR';

export const FETCH_POSTS_INNER_PAGE = 'FETCH_POSTS_INNER_PAGE';
export const FETCH_POSTS_INNER_PAGE_ERROR = 'FETCH_POSTS_INNER_PAGE_ERROR';
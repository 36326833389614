
export const dateFormat = (paramDate: Date) => {

    var date = new Date(paramDate);
    return date.toLocaleDateString();
}

export const tryRequire = async (slug: string) => {
    try {
        return require(`../Json/${slug}.json`);      
    } catch (err) {
        return false;
    }
};
import React from 'react';
import Front from "./Layouts/Front"; // Load Layout

function App() {
    return (
        <Front />
    );
}

export default App;

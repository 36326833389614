import axios from "axios";
import { Method } from "../Types";

const baseURL = process.env.REACT_APP_API_BASE_URL;

/**
 * Api Hit Call Method
 */
export function postApiHit(endpoint: string, method: Method, data?: object) {

    return axios({
        method: method,
        url: endpoint,
        baseURL: baseURL,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        data: (method === 'post') ? data : undefined
    }).then(resp => {
        if (resp.status === 200) {
            return resp.data;
        }  
    });
}